import React from "react"
import "./SupportGuide.css"
import dashboard from "../../../images/supportOptimized/dashboard2.png"
import scanAPI from "../../../images/supportOptimized/scanAPI.png"
import analytics2 from "../../../images/supportOptimized/Location & scanning image 2 .png"
import createCustomer from "../../../images/supportOptimized/Create customer slide - 1 .png"
import location from "../../../images/supportOptimized/choose location.png"
import validator from "../../../images/supportOptimized/Validator image 2.2 2.png"
import postUrls from "../../../images/supportOptimized/Location image 3 .png"
import APIAccess from "../../../images/supportOptimized/APIAccess3.png"
import { GrCircleInformation } from "react-icons/gr"
import { AiFillCaretRight } from "react-icons/ai"
import Fade from "react-reveal"

const SupportGuide = () => {
  return (
    <>
      <div className="container" id="support-container">
        <Fade bottom>
          <div className="supportTextArea">
            <h5>U-find</h5>
            <h1>Support Guide</h1>
          </div>
          <div className="stepsTitle">
            <h6>Please follow these steps to configure U-find correctly</h6>
          </div>
          <div className="stepsBox">
            <p>
              Open a web browser and log in to your Meraki dashboard at&nbsp;
              <a
                href="https://dashboard.meraki.com"
                target="_blank"
                rel="noopener noreferrer"
                className="merakiLink"
              >
                https://dashboard.meraki.com
              </a>
            </p>
            <div className="step1">
              <h4>
                {" "}
                <AiFillCaretRight /> Step 1 - Generate Meraki API Key{" "}
              </h4>
            </div>
            <h5 className="enable1">Enable API access</h5>
            <p>
              For access to the API, first enable the API for your organization
              under{" "}
              <b>
                Organization {">"} Settings {">"} Dashboard API access. (my
                profile)
              </b>
            </p>
            <div className="dashboardImgDiv">
              <img
                src={dashboard}
                alt="support dashboard API"
                className="dashboardImg"
              />
            </div>

            <p>
              After enabling the API, got the{" "}
              <span style={{ color: "green" }}>my profile</span> page to
              generate an API key. This API key will be associated with the
              Dashboard Administrator account which generates it, and will
              inherit the same permissions as that account. You generate,
              revoke, and regenerate your API key on your profile.
            </p>
            <div className="step1Note">
              <p>
                <GrCircleInformation className="infoIcon" />
                &nbsp;
                <b>Note:</b> &nbsp;Keep your API key safe as it provides
                authentication to all of your organizations with the API
                enabled. If your API key is shared, you can regenerate your API
                key at any time. This will revoke the existing API key.
              </p>
            </div>
            <div className="accessDiv">
              <img
                src={APIAccess}
                alt="API Access key button"
                className="generateKeyImg"
              />
            </div>
            <br />
            <div className="step1">
              <h4>
                {" "}
                <AiFillCaretRight /> Step 2 - U-find: insert Meraki API key
              </h4>
            </div>
            <p>
              On U-find create customer page, insert Meraki API key and fill the
              form with the correct information and press the next button.
            </p>
            <div className="createCustomerDiv">
              <img
                src={createCustomer}
                alt="create customer form"
                className="createCustomerImg"
              />
            </div>
            <h5 className="enable">
              Check your email for the confirmation code
            </h5>
            <p>
              You will receive a confirmation code in your email account. Fill
              the form with the confirmation code and press next. If you didn’t
              receive it, press the resend link.
            </p>
            <h5 className="enable">Select the location and location type</h5>
            <p>
              Choose the location and your location type between office or
              retail.
            </p>
            <div className="createCustomerDiv2">
              <img
                src={location}
                alt="location"
                className="createCustomerImg2"
              />
            </div>
            <div className="step2Note">
              <p>
                <GrCircleInformation className="infoIcon" />
                &nbsp;
                <b>Note:</b> &nbsp;after choosing your location type, choose
                your employees and guests SSIDs and also, assign levels.
              </p>
            </div>
            <div className="step1">
              <h4>
                {" "}
                <AiFillCaretRight /> Step 3 - Meraki: Create Scan API Validator
              </h4>
            </div>
            <p>
              Go to: <b>Network-wide {">"} General</b> on your Meraki dashboard.
            </p>
            <div className="scanApiDiv">
              <img src={scanAPI} alt="scanAPI " className="scanAPIImg" />
            </div>
            <h5 className="enable">Enable Analytics</h5>
            <p>
              Enable analytics and insert a secret word of your choice in the
              secret field. Then copy both the validator number and the secret
              and paste them in the app.
            </p>
            <div className="analyticsDiv">
              <img src={analytics2} alt="analytics" className="analyticsImg1" />
            </div>
            <p>
              <GrCircleInformation className="infoIcon" />
              &nbsp;
              <b>Note:</b> &nbsp;the API version must be set to V3.
            </p>
            <div className="step1">
              <h4>
                {" "}
                <AiFillCaretRight /> Step 4 - Meraki: Copy the validator URL
              </h4>
            </div>
            <p>Copy the validator URL from U-find app.</p>
            <div className="validatorDiv">
              <img src={validator} alt="scanAPI " className="validatorImg" />
            </div>
            <p>Paste it to the Post URL form field in Meraki dashboard.</p>
            <div className="postUrlDiv">
              <img src={postUrls} alt="postUrls " className="postUrlsImg" />
            </div>
            <div className="step1">
              <h4>
                {" "}
                <AiFillCaretRight /> Step 5 - Finish the configuration
              </h4>
            </div>
            <p>
              To finish the configuration just press the next button on the
              U-find app form and then press the validate button on the meraki
              dashboard.
            </p>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default SupportGuide
